import { reactive, ref, toRefs } from 'vue';
type FormData = {
  loginName: string | null;
  loginPwd: string | null;
  customerName: string;
  customerId: number | string;
  id: number | string;
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    loginName: null,
    loginPwd: null,
    customerName: null,
    customerId: null,
    id: null,
    ...params,
  });
  const customFormData = reactive({
    customerOption: [],
  });
  const rules = reactive({
    loginName: [
      { required: true, message: '请输入用户名', trigger: ['blur', 'change'] },
    ],
    customerName: [
      {
        required: true,
        message: '请输入所属客户',
        trigger: ['blur', 'change'],
      },
    ],
  });
  const blurCheckName = () => {
    let val = formData.loginName;
    val = String(val).replace(/[^A-Za-z0-9\u4e00-\u9fa5]/g, '');
    if (val && val !== 'null') {
      formData.loginName = val;
    } else {
      formData.loginName = null;
    }
  };
  const blurPwdword = () => {
    let val = formData.loginPwd;
    val = String(val).replace(/[^A-Za-z0-9]/g, '');
    if (val && val !== 'null') {
      formData.loginPwd = val;
    } else {
      formData.loginPwd = null;
    }
  };
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  return {
    rules,
    formData,
    formRef,
    setFormData,
    ...toRefs(customFormData),
    blurCheckName,
    blurPwdword,
  };
};
