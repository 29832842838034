import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import { httpPost } from '@/api';
import { erpConfirm, successMessage, errorMessage } from '@/utils/publicMethods';
import Clipboard from 'clipboard';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'userName',
      value: '',
      placeholder: '请输入用户名',
    },
    {
      type: 'signSelect',
      prop: 'status',
      value: 'ENABLE',
      options: [
        {
          value: 'ENABLE',
          label: '启用',
        },
        {
          value: 'DISABLE',
          label: '停用',
        },
      ],
      placeholder: '请选择状态',
    },
    {
      type: 'input',
      prop: 'customerName',
      value: '',
      placeholder: '请输入客户',
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '用户编号',
      prop: 'userCode',
      minWidth: 188,
    },
    {
      label: '用户名',
      prop: 'userName',
      minWidth: 190,
    },
    {
      label: '状态',
      prop: 'status',
      component: defineComponent({
        template: `
          <div>
              {{statusToChinese(row.status)}}
          </div>
        `,
        props: ['row'],
        setup() {
          const statusToChinese = (val) => {
            let state = '';
            switch (val) {
              case 'ENABLE': {
                state = '启用';
                break;
              }
              case 'DISABLE': {
                state = '停用';
                break;
              }
            }
            return state;
          };
          return { statusToChinese };
        },
      }),
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 400,
    },
    {
      label: '初始密码',
      component: defineComponent({
        template: `
          <div v-if="row.initPassword && hasPermission('uac:erpAppUser:copyInitPwd')" 
          :data-clipboard-text="row.initPassword"
          class="crm-cell-link copy-btn" @click="clickFn(row)">
          复制
          </div>
          <div v-else>-</div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const clickFn = () => {
            const ClipboardObj = new Clipboard('.copy-btn');
            ClipboardObj.on('success', () => {
              successMessage('复制成功');
              ClipboardObj.destroy();
            });
            ClipboardObj.on('error', () => {
              ClipboardObj.destroy();
            });
          };
          return { clickFn };
        },
      }),
      prop: 'initPassword',
      minWidth: 127,
    },
    {
      label: '操作时间',
      prop: 'updateTime',
      minWidth: 204,
    },
    {
      label: '操作人',
      prop: 'lastOperator',
      minWidth: 137,
    },
    {
      label: '操作',
      prop: '',
      width: 290,
      component: defineComponent({
        template: `
          <div class="crm-btn-groups">
            <el-button v-if="hasPermission('uac:erpAppUser:saveErpAppUacUser')" type="primary" plain @click="edit(row)">编辑</el-button>

            <el-button v-if="row.status === 'ENABLE' && hasPermission('uac:erpAppUser:modifyUserStatusById')" type="warning" plain @click="disableFn(row)">停用</el-button>

            <el-button v-if="row.status === 'DISABLE' && hasPermission('uac:erpAppUser:modifyUserStatusById')" type="primary" plain @click="enableFn(row)">启用</el-button>

            <el-button v-if="row.status === 'DISABLE'" type="default" plain @click="delFn(row)">删除</el-button>
          </div>
        `,
        props: ['row'],
        setup() {
          const delFn = (row) => {
            erpConfirm('是否确认删除该客户账号?').then(async () => {
              let res = await httpPost('/erpappuac/erpAppUser/deleteErpAppUacUser', {
                id: row.id,
              });
              if (res.code === 200) {
                Instance.setupState.refreshTable(true);
              } else {
                errorMessage(res.message);
              }
            });
          };
          /** 编辑 */
          const edit = (row) => {
            Instance.setupState.rowData.value = row;
            Instance.setupState.setFormType(2);
            Instance.setupState.showVisible();
          };
          /** 停用 */
          const disableFn = (row) => {
            const confirmMsg = '确定停用该用户名吗?';
            erpConfirm(confirmMsg).then(async () => {
              const params = {
                status: 'DISABLE',
                userId: row.id,
              };
              const res = await httpPost('/erpappuac/erpAppUser/modifyUserStatusById', params);
              if (res.code === 200) {
                successMessage('用户名已停用');
                Instance.setupState.refreshTable(true);
              } else {
                errorMessage(res.message);
              }
            });
          };
          /** 启用 */
          const enableFn = (row) => {
            const confirmMsg = '确定启用该用户名吗?';
            erpConfirm(confirmMsg).then(async () => {
              const params = {
                status: 'ENABLE',
                userId: row.id,
              };
              const res = await httpPost('/erpappuac/erpAppUser/modifyUserStatusById', params);
              if (res.code === 200) {
                successMessage('用户名已启用');
                Instance.setupState.refreshTable(true);
              } else {
                errorMessage(res.message);
              }
            });
          };
          return { edit, disableFn, enableFn, delFn };
        },
      }),
    },
  ];
  return { filterOptions, columnList, tableRef };
};
