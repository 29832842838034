import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mali-edit__form" }
const _hoisted_2 = { class: "over-tootip" }
const _hoisted_3 = { class: "crm-button__group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_CrmFormLayout = _resolveComponent("CrmFormLayout")!

  return (_openBlock(), _createBlock(_component_CrmFormLayout, {
    title: _ctx.setTitleLabel(_ctx.formType, ['新建', '编辑']) + '用户',
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.formData,
        "label-position": "top",
        ref: "formRef",
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "用户名",
                      prop: "loginName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          clearable: "",
                          modelValue: _ctx.formData.loginName,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.loginName) = $event)),
                          placeholder: "请输入用户名",
                          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.blurCheckName($event, 'loginName'))),
                          type: "text",
                          maxlength: 15
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "所属客户",
                      prop: "customerName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          "popper-append-to-body": false,
                          modelValue: _ctx.formData.customerName,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.customerName) = $event)),
                          class: "mali-full__input",
                          clearable: "",
                          filterable: "",
                          remote: "",
                          disabled: _ctx.formType > 1,
                          "remote-method": _ctx.getCustomerOption,
                          onBlur: _ctx.initCustomerOption,
                          onChange: _ctx.getCustomerId,
                          onClear: _ctx.clearCustomer,
                          placeholder: "请输入所属客户"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerOption, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.id,
                                label: item.customerName,
                                value: item.id
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_tooltip, {
                                    content: item.customerName,
                                    placement: "top-start",
                                    disabled: item.customerName.length < 28
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_2, _toDisplayString(item.customerName), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["content", "disabled"])
                                ]),
                                _: 2
                              }, 1032, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "disabled", "remote-method", "onBlur", "onChange", "onClear"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.formType === 2)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 0,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "密码" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              clearable: "",
                              modelValue: _ctx.formData.loginPwd,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.loginPwd) = $event)),
                              placeholder: "请输入8位数字或英文密码",
                              onBlur: _ctx.blurPwdword,
                              type: "text",
                              maxlength: 8
                            }, null, 8, ["modelValue", "onBlur"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "crm-form__button primary",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.saveFormData && _ctx.saveFormData(...args)))
        }, "保存"),
        _createElementVNode("button", {
          class: "crm-form__button cancel",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.close(false)))
        }, "取消")
      ])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}