
import { httpPost } from '@/api';
import { ajaxLoading, errorMessage, setTitleLabel, successMessage } from '@/utils/publicMethods';
import { defineComponent, computed } from 'vue';
import useFormData from './useAccountFormData';
export default defineComponent({
  components: {},
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const params: any = {};
    const { rules, formData, formRef, setFormData, blurCheckName, customerOption, blurPwdword } = useFormData(params);
    const readOnly = computed(() => {
      return props.formType > 2;
    });
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const submitData = async (data) => {
      const params = {
        ...data,
      };
      ajaxLoading.lock();
      const res = await httpPost('/erpappuac/erpAppUser/saveErpAppUacUser', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        successMessage('保存成功');
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    const getCustomerOption = async (name) => {
      const params = {
        customerName: name,
      };
      const res = await httpPost('/malicrm/business/selectCustomer', params);
      if (res.code === 200) {
        customerOption.value = [];
        customerOption.value = res.result.map((item) => {
          return { customerName: item.customerName, id: item.id };
        });
      } else {
        errorMessage(res.message);
      }
    };
    // 失去焦点时重置客户筛选条件
    const initCustomerOption = () => {
      // 失去焦点时重置筛选条件
      if (customerOption.value && customerOption.value.length <= 0) {
        setTimeout(() => {
          getCustomerOption('');
        }, 500);
      }
    };
    // 获取客户 id
    const getCustomerId = (val) => {
      customerOption.value.forEach((item) => {
        if ((item as any).id === val) {
          formData.customerName = (item as any).customerName;
          formData.customerId = (item as any).id;
        }
      });
    };
    const clearCustomer = () => {
      getCustomerOption('');
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          const data = {};
          const { customerId, loginName, id, loginPwd } = formData;
          (data as any).loginName = loginName;
          (data as any).id = id;
          if (props.formType === 1) {
            (data as any).customerId = customerId;
          }
          if (props.formType === 2) {
            if (formData.loginPwd) {
              (data as any).loginPwd = loginPwd;
              const len = (loginPwd as any).toString().length;
              if (len !== 8) {
                errorMessage('密码需为8位数字或英文');
                return;
              }
            }
          }
          submitData(data);
        } else {
          return false;
        }
      });
    };
    getCustomerOption('');
    if (props.formType > 1) {
      setFormData(props.data);
      formData.loginName = (props.data as any).userName;
    }
    return {
      close,
      formData,
      saveFormData,
      setFormData,
      rules,
      formRef,
      setTitleLabel,
      readOnly,
      blurCheckName,
      getCustomerOption,
      customerOption,
      initCustomerOption,
      getCustomerId,
      clearCustomer,
      blurPwdword,
    };
  },
});
